import { Grid, LoadingButton, useSnackbar } from "@enerbit/base";
import { date } from "@formkit/tempo";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";
import { formTeam } from "../../form-builders";
import type {
	ICoverage,
	ICoverageBack,
	IOperatorItem,
	IUpdateTeam,
	IWorkGroupsItem,
} from "../../interfaces";
import { UpdateTeamSchema } from "../../schemas";
import { useTeamStore } from "../../store";
import { FormItem } from "../dynamic-form/FormItem";
import { DynamicLocationForm } from "./DynamicLocation";
import OriginTeam from "./OriginTeam";

interface Props {
	team: IWorkGroupsItem;
	handleClose: () => void;
}

interface ICoverageFormated {
	state: string;
	city: string;
}

interface GroupedData {
	department: string;
	cities: string[];
}

export const UpdateTeamForm = ({ team, handleClose }: Props) => {
	const { updateTeam, loadingTeam } = useTeamStore();

	const { enqueueSnackbar } = useSnackbar();

	const currentCoverage =
		team?.work_groups_coverage || team.workgroups_coverage;

	const transformDataFromBackend = (
		backendData: ICoverageBack[],
	): GroupedData[] => {
		const groupedData = backendData.reduce<
			Record<string, { department: string; cities: string[] }>
		>((acc, { state, city }) => {
			if (acc[state]) {
				acc[state].cities.push(city);
			} else {
				acc[state] = { department: state, cities: [city] };
			}
			return acc;
		}, {});

		return Object.values(groupedData);
	};

	const methods = useForm<IUpdateTeam>({
		resolver: zodResolver(UpdateTeamSchema),
		defaultValues: {
			...team,
			work_groups_coverage: transformDataFromBackend(
				currentCoverage,
			) as ICoverage[],
			schedules: {
				...team.schedules,
				overtime:
					team.schedules.overtime?.length === 0
						? null
						: team.schedules.overtime,
			},
			operator_ids: team.operators,
		},
		mode: "onChange",
	});

	const {
		handleSubmit,
		formState: { errors },
	} = methods;

	const onSubmit = async (data: IUpdateTeam) => {
		try {
			const emptyMeasurementIDs = (data.operator_ids as IOperatorItem[])
				.filter((operatorItem) => {
					return (
						!operatorItem.pii ||
						!operatorItem.pii.form_data ||
						!operatorItem.pii.form_data.measurement_type_trained ||
						operatorItem.pii.form_data.measurement_type_trained.length === 0
					);
				})
				.map((operatorItem) => operatorItem.username);

			if (emptyMeasurementIDs.length > 0) {
				emptyMeasurementIDs.map((name) => {
					enqueueSnackbar(`El operador ${name} no tiene medidas asociadas.`, {
						variant: "warning",
					});
				});
				return;
			}

			const noCoordinators = (data.operator_ids as IOperatorItem[]).every(
				(operatorItem) => !operatorItem.pii?.form_data?.coordinator,
			);

			if (noCoordinators) {
				enqueueSnackbar("Ningún operador es coordinador.", {
					variant: "warning",
				});
				return;
			}

			const inactiveOperators = (data.operator_ids as IOperatorItem[]).filter(
				(operatorItem) => !operatorItem.is_active,
			);

			if (inactiveOperators.length > 0) {
				for (const operator of inactiveOperators) {
					enqueueSnackbar(`El operador ${operator.username} está inactivo.`, {
						variant: "warning",
					});
				}
				return;
			}
			const currentCoverage = methods.watch(
				"work_groups_coverage",
			) as ICoverage[];
			const transformedData = currentCoverage.flatMap((coverage) =>
				coverage.cities.map((city: string) => ({
					state: coverage.department,
					city,
				})),
			);

			await updateTeam({
				...data,
				operator_ids: (data.operator_ids as IOperatorItem[]).map(
					(operator) => operator.id,
				),
				schedules: {
					...data.schedules,
					timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
					overtime: data.schedules.overtime ? data.schedules.overtime : [],
				},
				ended_at: date(data.ended_at),
				work_groups_coverage: transformedData,
			});
			enqueueSnackbar("Usuario actualizado con éxito.", { variant: "success" });
			handleClose();
		} catch (error) {
			console.log(error);
			enqueueSnackbar((error as Error).message, { variant: "error" });
		}
	};

	return (
		<Grid container>
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Grid container spacing={3}>
						<OriginTeam />
						<DynamicLocationForm />
						{formTeam.map((formField, index) => {
							return <FormItem {...formField} key={index.toString()} />;
						})}
						<Grid item xs={12}>
							<LoadingButton
								id="update-team"
								type="submit"
								variant="contained"
								color="secondary"
								loading={loadingTeam}
							>
								Actualizar cuadrilla
							</LoadingButton>
						</Grid>
					</Grid>
				</form>
			</FormProvider>
		</Grid>
	);
};
