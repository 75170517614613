import { Grid, LoadingButton, useSnackbar } from "@enerbit/base";
import { date } from "@formkit/tempo";
import { zodResolver } from "@hookform/resolvers/zod";
import type { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { formTeam } from "../../form-builders";
import type {
	ICoverage,
	IOperatorItem,
	IPostWorkGroups,
} from "../../interfaces";
import { CreateTeamSchema } from "../../schemas";
import { useTeamStore } from "../../store";
import { FormItem } from "../dynamic-form/FormItem";
import { DynamicLocationForm } from "./DynamicLocation";
import OriginTeam from "./OriginTeam";

interface Props {
	handleClose: () => void;
}

export const CreateTeamForm: FC<Props> = ({ handleClose }) => {
	const { createTeam, loadingTeam } = useTeamStore();

	const { enqueueSnackbar } = useSnackbar();

	const methods = useForm<IPostWorkGroups>({
		resolver: zodResolver(CreateTeamSchema),
		defaultValues: {
			operator_ids: [],
			work_groups_coverage: [{ state: "", city: "" }],
			schedules: {
				week: {
					monday: null,
					tuesday: null,
					wednesday: null,
					thursday: null,
					friday: null,
				},
				weekend: null,
				overtime: null,
			},
			mobility: [],
			ended_at: "",
		},
		mode: "onChange",
	});

	const {
		handleSubmit,
		formState: { errors },
		watch,
	} = methods;

	const onSubmit = async (data: IPostWorkGroups) => {
		try {
			const emptyMeasurementIDs = (data.operator_ids as IOperatorItem[])
				.filter((operatorItem) => {
					return (
						!operatorItem.pii ||
						!operatorItem.pii.form_data ||
						!operatorItem.pii.form_data.measurement_type_trained ||
						operatorItem.pii.form_data.measurement_type_trained.length === 0
					);
				})
				.map((operatorItem) => operatorItem.username);

			if (emptyMeasurementIDs.length > 0) {
				for (const name of emptyMeasurementIDs) {
					enqueueSnackbar(`El operador ${name} no tiene medidas asociadas.`, {
						variant: "warning",
					});
				}
				return;
			}

			const noCoordinators = (data.operator_ids as IOperatorItem[]).every(
				(operatorItem) => !operatorItem.pii?.form_data?.coordinator,
			);

			if (noCoordinators) {
				enqueueSnackbar("Ningún operador es coordinador.", {
					variant: "warning",
				});
				return;
			}

			const inactiveOperators = (data.operator_ids as IOperatorItem[]).filter(
				(operatorItem) => !operatorItem.is_active,
			);

			if (inactiveOperators.length > 0) {
				for (const operator of inactiveOperators) {
					enqueueSnackbar(`El operador ${operator.username} está inactivo.`, {
						variant: "warning",
					});
				}
				return;
			}

			const currentCoverage = watch("work_groups_coverage") as ICoverage[];
			const transformedData = currentCoverage.flatMap((coverage: ICoverage) =>
				coverage.cities.map((city: string) => ({
					state: coverage.department,
					city,
				})),
			);
			await createTeam({
				...data,
				operator_ids: (data.operator_ids as IOperatorItem[]).map(
					(operator) => operator.id,
				),
				city: watch("city"),
				schedules: {
					...data.schedules,
					overtime: data.schedules.overtime ? data.schedules.overtime : [],
					timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
				},
				ended_at: date(data.ended_at),
				work_groups_coverage: transformedData,
			});
			enqueueSnackbar("Cuadrilla creada con éxito.", { variant: "success" });
			handleClose();
		} catch (error) {
			console.log(error);
			enqueueSnackbar((error as Error).message, { variant: "error" });
		}
	};

	return (
		<Grid container>
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Grid container spacing={3}>
						<OriginTeam />
						<DynamicLocationForm />
						{formTeam.map((formField, index) => {
							return <FormItem {...formField} key={index.toString()} />;
						})}
						<Grid item xs={12}>
							<LoadingButton
								id="create-team"
								type="submit"
								variant="contained"
								color="secondary"
								loading={loadingTeam}
							>
								Crear cuadrilla
							</LoadingButton>
						</Grid>
					</Grid>
				</form>
			</FormProvider>
		</Grid>
	);
};
