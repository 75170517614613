import {
	Autocomplete,
	Box,
	CircularProgress,
	FormControl,
	Grid,
	InputLabel,
	TextField,
	Typography,
} from "@enerbit/base";
import React, { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import type { IItemsJson } from "../../interfaces";
import { getCities, getDepartments } from "../../services/getOptions";

const OriginTeam = () => {
	const {
		control,
		setValue,
		watch,
		formState: { errors },
	} = useFormContext();

	const [stateOptions, setStateOptions] = useState<IItemsJson[]>([]);
	const [isLoadingStates, setIsLoadingStates] = useState(false);
	const [cityOptions, setCityOptions] = useState<IItemsJson[]>([]);
	const [isLoadingCities, setIsLoadingCities] = useState(false);
	const selectedState = watch("state");
	const selectedCity = watch("city");

	useEffect(() => {
		const loadStates = async () => {
			setIsLoadingStates(true);
			const states = await getDepartments();
			setStateOptions(states);
			setIsLoadingStates(false);
		};

		loadStates();
	}, []);

	useEffect(() => {
		const loadCities = async (state: string) => {
			if (state) {
				setIsLoadingCities(true);
				const value = stateOptions.find(({ text }) => text === state)?.value;
				const cities = await getCities(value?.toString() ?? "");
				setCityOptions(cities);
				setIsLoadingCities(false);
			} else {
				setCityOptions([]);
			}
		};

		loadCities(selectedState);
	}, [selectedState, stateOptions]);

	const handleStateChange = (value: IItemsJson | null) => {
		setValue("state", value ? value.text : "");
		setValue("city", ""); // Resetea la ciudad cuando se cambia el estado
	};

	return (
		<Box sx={{ paddingLeft: "24px", mt: 2, width: "100%" }}>
			<Typography color="primary" variant="h6" my={2}>
				Origen de la cuadrilla
			</Typography>

			<Grid item xs={12} sx={{ mb: 2 }}>
				<InputLabel>Departamento</InputLabel>
				<FormControl fullWidth>
					<Controller
						name="state"
						control={control}
						render={({ field: { onChange, ...field } }) => (
							<Autocomplete
								{...field}
								id="state-autocomplete"
								fullWidth
								sx={{ "& fieldset": { borderRadius: "14px" } }}
								options={stateOptions}
								getOptionLabel={(option) => option.text}
								loading={isLoadingStates}
								loadingText="Cargando..."
								noOptionsText="Sin opciones."
								onChange={(_, value) => handleStateChange(value)}
								value={
									stateOptions.find(
										(option) => option.text === selectedState,
									) || null
								}
								renderInput={(params) => (
									<TextField
										{...params}
										fullWidth
										error={!!errors?.state}
										helperText={errors?.state?.message}
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<>
													{isLoadingStates ? (
														<CircularProgress size={20} />
													) : null}
													{params.InputProps.endAdornment}
												</>
											),
										}}
									/>
								)}
							/>
						)}
					/>
				</FormControl>
			</Grid>

			<Grid item xs={12} sx={{ mb: 2 }}>
				<InputLabel>Ciudad</InputLabel>
				<FormControl fullWidth>
					<Controller
						name="city"
						control={control}
						render={({ field: { onChange, ...field } }) => (
							<Autocomplete
								{...field}
								id="city-autocomplete"
								fullWidth
								sx={{ "& fieldset": { borderRadius: "14px" } }}
								options={cityOptions}
								getOptionLabel={(option) => option.text}
								loading={isLoadingCities}
								loadingText="Cargando..."
								noOptionsText="Sin opciones."
								onChange={(_, value) =>
									setValue("city", value ? value.text : "")
								}
								value={
									cityOptions.find((option) => option.text === selectedCity) ||
									null
								}
								renderInput={(params) => (
									<TextField
										{...params}
										fullWidth
										error={!!errors?.city}
										helperText={errors?.city?.message}
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<>
													{isLoadingCities ? (
														<CircularProgress size={20} />
													) : null}
													{params.InputProps.endAdornment}
												</>
											),
										}}
									/>
								)}
							/>
						)}
					/>
				</FormControl>
			</Grid>
		</Box>
	);
};

export default OriginTeam;
