import { api, decodedJwt, geopoliticsApi } from "@enerbit/base";
import type {
	IItemsJson,
	IRespCity,
	IRespOperators,
	IRespState,
	IRespWorkGroups,
} from "../interfaces";

export const getDepartments: () => Promise<IItemsJson[]> = async () => {
	try {
		const { data } = await geopoliticsApi.get<IRespState>(
			"/states/?country_code=CO",
		);

		const options: IItemsJson[] = data.items.map(({ name, state_code }) => ({
			text: name,
			value: state_code,
		}));

		return options;
	} catch (error) {
		return [];
	}
};

export const getCities: (department?: string) => Promise<IItemsJson[]> = async (
	department,
) => {
	try {
		if (!department) return [];

		const { data } = await geopoliticsApi.get<IRespCity>(
			`/cities/?state_code=${department}&country_code=CO`,
		);

		return data.items.map(({ name }) => ({
			text: name,
			value: name,
		}));
	} catch (error) {
		console.error(error);
		return [];
	}
};

export const getOperators = async (email: string) => {
	const decodedToken = decodedJwt();

	const role = decodedToken?.role;
	const company = decodedJwt()?.company_id;

	console.log(role, company);

	const { data } = await api.get<IRespOperators>(
		`/ops-users/operators/?username=${email}${role === "contratista" ? `&company_id=${company}` : ""}&page=0&size=5&rol_code=operacion_energia_digital,opr&is_active=true`,
	);
	return data.items.filter(({ pii }) => pii);
};

export const getTeam = async (email: string) => {
	const { data } = await api.get<IRespWorkGroups>(
		`/work-groups/work-groups?page=0&size=5&username=${email}`,
	);
	return data.items.filter(({ operators }) => operators);
};

export const getWorkGroupsByUsername = async (username: string) => {
	const { data } = await api.get(
		`/work-groups/work-groups?username=${username}`,
	);

	return data.items;
};
